<template>
    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
        <SmartTable ref="tesseratiinmodifica" :is_dialog="true" url="/enrolled-user-pending-changes/index" base_url="/enrolled-user-pending-changes" base_path="/enrolled-user" :fixed_filters="(idannuality ? [{ name: 'idannuality', filter: idannualitycalculated }] : null)" v-on:close-modal="closeModal">

            <template v-slot:before-filters>
                <div class="pb-3">
                    <a href="#" class="mt-2 btn btn-success font-weight-bolder mr-3" @click.prevent="applicaModificheSelezionati(0)">
                        <i class="fas fa-check"></i>
                        Applica Modifiche Selezionati
                    </a>

                    <a href="#" class="mt-2 btn btn-info font-weight-bolder mr-3" @click.prevent="applicaModificheInvioConiSelezionati(0)">
                        <i class="fas fa-paper-plane"></i>
                        Applica Modifiche e Invia CONI Selezionati
                    </a>

                    <a href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="scartaModificheSelezionati(0)">
                        <i class="fas fa-trash-alt"></i>
                        Scarta Modifiche Selezionati
                    </a>
                </div>

                <div class="pb-3">
                    <form>
                        <div class="form-group">
                            <label for="idannuality">Annualità:</label>
                            <button class="btn btn-ssmm btn-primary ml-2" type="button" @click.prevent="selectAll()">Seleziona Tutti</button>
                            <button class="btn btn-ssmm btn-primary ml-2" type="button" @click.prevent="deselectAll()">Deseleziona Tutti</button>
                            <multiselect style="width: 100%;"
                                v-model="idannuality"
                                :options="annualities"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                placeholder="Seleziona un'annualità"
                                label="label"
                                track-by="id"
                                :allow-empty="true"
                                selectLabel=""
                                selectedLabel=""
                                deselectLabel=""
                                showLabels=""
                            >
                                <template v-slot:noOptions>
                                    Nessuna annualità disponibile
                                </template>
                            </multiselect>
                        </div>
                    </form>
                </div>

                <b-modal v-if="currentItem.has_pending_changes && ($store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin')" ref="enrolledUserModalChanges" size="xl" hide-footer title="Modifiche Tesserato">
                    <b-overlay :show="loading" rounded="sm" opacity="0.50" blur="blur" spinner-variant="primary">
                        <div class="card card-custom card-stretch gutter-b topform">
                            <div class="card-header border-0 py-5">
                                <div class="card-title">
                                    <h3 class="card-label">
                                        <a href="#" @click.prevent="hideEnrolledUserModalChanges" class="mt-2 btn btn-danger btn-sm font-weight-bolder">
                                            <i class="fas fa-window-close icon-sm"></i>
                                            Chiudi
                                        </a>
                                    </h3>
                                </div>
                                <div class="card-toolbar">
                                    <a href="#" @click.prevent="applicaModificheSelezionati(currentItem.id)" class="btn btn-sm btn-success mr-3 font-weight-bolder" title="Applica Modifiche" alt="Applica Modifiche">
                                        <i class="fas fa-check"></i>
                                        Applica Modifiche
                                    </a>

                                    <a href="#" @click.prevent="applicaModificheInvioConiSelezionati(currentItem.id)" class="btn btn-sm btn-info mr-3 font-weight-bolder" title="Applica Modifiche e invia al CONI" alt="Applica Modifiche e invia al CONI">
                                        <i class="fas fa-paper-plane"></i>
                                        Applica Modifiche e invia al CONI
                                    </a>

                                    <a href="#" @click.prevent="scartaModificheSelezionati(currentItem.id)" class="btn btn-sm btn-danger mr-3 font-weight-bolder" title="Scarta Modifiche" alt="Scarta Modifiche">
                                        <i class="fas fa-trash-alt"></i>
                                        Scarta Modifiche
                                    </a>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table table-hover table-head-custom table-vertical-center table-head-bg table-bordered">
                                        <thead>
                                            <tr class="text-left">
                                                <th class="bg-primary">
                                                    <a href="javascript:void(0);" class="text-white text-hover-white">&nbsp;</a>
                                                </th>
                                                <th class="bg-primary">
                                                    <a href="javascript:void(0);" class="text-white text-hover-white">Originale</a>
                                                </th>
                                                <th class="bg-primary">
                                                    <a href="javascript:void(0);" class="text-white text-hover-white">Modificato</a>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            <tr v-for="field in fields" :class="{ 'bg-success': field.original === field.modified, 'bg-danger': field.original !== field.modified }">
                                                <th class="bg-primary">
                                                    <a href="javascript:void(0);" class="text-white text-hover-white">{{field.label}}</a>
                                                </th>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div>
                                                            <span class="text-white text-hover-white mb-1 font-size-lg">{{field.original}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div>
                                                            <span class="text-white text-hover-white mb-1 font-size-lg">{{field.modified}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="card-footer border-0 py-5">
                                <div class="d-flex flex-wrap justify-content-between">
                                    <div>
                                        <a href="#" @click.prevent="hideEnrolledUserModalChanges" class="mt-2 btn btn-danger font-weight-bolder">
                                            <i class="fas fa-window-close"></i>
                                            Chiudi
                                        </a>
                                    </div>

                                    <div>
                                        <a href="#" @click.prevent="applicaModificheSelezionati(currentItem.id)" class="mt-2 btn btn-success mr-3 font-weight-bolder" title="Applica Modifiche" alt="Applica Modifiche">
                                            <i class="fas fa-check"></i>
                                            Applica Modifiche
                                        </a>

                                        <a href="#" @click.prevent="applicaModificheInvioConiSelezionati(currentItem.id)" class="mt-2 btn btn-info mr-3 font-weight-bolder" title="Applica Modifiche e invia al CONI" alt="Applica Modifiche e invia al CONI">
                                            <i class="fas fa-paper-plane"></i>
                                            Applica Modifiche e invia al CONI
                                        </a>

                                        <a href="#" @click.prevent="scartaModificheSelezionati(currentItem.id)" class="mt-2 btn btn-danger mr-3 font-weight-bolder" title="Scarta Modifiche" alt="Scarta Modifiche">
                                            <i class="fas fa-trash-alt"></i>
                                            Scarta Modifiche
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-overlay>
                </b-modal>
            </template>

            <template v-slot:item-actions="slotProps">
                <td class="text-nowrap pr-0 text-right">
                    <a v-if="slotProps.values.item.has_pending_changes && ($store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin')" href="#" @click.prevent="showEnrolledUserModalChanges(slotProps.values.item)" class="btn btn-icon btn-circle btn-sm btn-primary mr-3" title="Mostra Modifiche" alt="Mostra Modifiche">
                        <i class="fas fa-exchange-alt"></i>
                    </a>

                    <a v-if="slotProps.values.item.has_pending_changes && ($store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin')" href="#" @click.prevent="applicaModificheSelezionati(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-success mr-3" title="Applica Modifiche" alt="Applica Modifiche">
                        <i class="fas fa-check"></i>
                    </a>

                    <a v-if="slotProps.values.item.has_pending_changes && ($store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin')" href="#" @click.prevent="applicaModificheInvioConiSelezionati(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Applica Modifiche e invia al CONI" alt="Applica Modifiche e invia al CONI">
                        <i class="fas fa-paper-plane"></i>
                    </a>

                    <a v-if="slotProps.values.item.has_pending_changes && ($store.getters.currentUser.usertype === 'Segreteria Nazionale' || $store.getters.currentUser.usertype === 'Superadmin')" href="#" @click.prevent="scartaModificheSelezionati(slotProps.values.item.id)" class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Scarta Modifiche" alt="Scarta Modifiche">
                        <i class="fas fa-trash-alt"></i>
                    </a>
                </td>
            </template>
        </SmartTable>
    </b-overlay>
</template>

<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import Swal from 'sweetalert2';
 import ApiService from "@/core/services/api.service";

 export default {
     components: {
         SmartTable
     },
     data() {
         return {
             loading: false,

             openedModal: false,

             currentItem: {
                 id: 0,
                 has_pending_changes: false,
             },

             fields: [
                 { name: 'surname', label: '', original: '', modified: '', },
                 { name: 'name', label: '', original: '', modified: '', },
                 { name: 'taxcode', label: '', original: '', modified: '', },
                 { name: 'email', label: '', original: '', modified: '', },
                 { name: 'phonenumber', label: '', original: '', modified: '', },
                 { name: 'certificatedate', label: '', original: '', modified: '', },
                 { name: 'activities', label: '', original: '', modified: '', },
                 { name: 'coni2activities', label: '', original: '', modified: '', },
             ],

            idannuality: null,
            annualities: [],
            idannualitycalculated: [],
         };
     },

    created() {
        let url = '/sportingclub/get-annualities';
        ApiService.query(url)
            .then((response) => {
                this.annualities = response.data.annualities;

                let idannuality = response.data.idannuality;

                let label = '';
                for (let i in this.annualities) {
                    if (this.annualities[i].id === idannuality) {
                        label = this.annualities[i].label;
                    }
                }

                this.idannuality = { id: response.data.idannuality, label: label };
            })
            .catch((error) => {
                console.log(error);
            })
    },

     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Tesseramenti", route: "/enrolled-user/index" },
             { title: "Modifiche in sospeso" }
         ]);
     },

    watch: {
        idannuality(value) {
            this.idannualitycalculated = [];

            for (let i in value) {
                if (i === 'id') {
                    this.idannualitycalculated.push(value.id);
                }
                else if (typeof value[i] === 'object') {
                    this.idannualitycalculated.push(value[i].id);
                }
            }

            let vm = this;
            setTimeout(function(){
                vm.$refs.tesseratiinmodifica.refresh();
            }, 100);
        }
    },

     methods: {
        selectAll() {
            let options = this.annualities === null ? [] : this.annualities;
            let value = [];
            for (let i in options) {
                value.push(JSON.parse(JSON.stringify(options[i])));
            }

            this.idannuality = value;
        },

        deselectAll() {
            this.idannuality = [];
        },

         applicaModificheSelezionati(id) {
             let title = 'Conferma di Applicare le modifiche ai Tesserati selezionati?';
             let text = "Sei sicuro di voler applicare le modifiche ai Tesserati selezionati?";

             if (id !== 0) {
                 title = 'Conferma di Applicare le modifiche al Tesserato selezionato?';
                 text = "Sei sicuro di voler applicare le modifiche al Tesserato selezionato?";
             }

             Swal.fire({
                 title: title,
                 text: text,
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let ids = [];
                     if (id === 0) {
                         for (let i in this.$refs.tesseratiinmodifica.items) {
                             if (this.$refs.tesseratiinmodifica.items[i].checked) ids.push(this.$refs.tesseratiinmodifica.items[i].id);
                         }
                     }
                     else {
                         ids.push(id);
                     }

                     if (ids.length) {
                         let url = '/enrolled-user-pending-changes/apply-changes';
                         let data = { ids: ids };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Modifiche applicate correttamente', {
                                               title: 'Modifiche applicate correttamente ai Tesserati selezionati',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.loading = false;

                                           this.$refs.tesseratiinmodifica.refresh();

                                           if (this.openedModal) {
                                               this.hideEnrolledUserModalChanges();
                                               this.openedModal = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'applicazione delle modifiche', {
                                               title: 'Errore nell\'applicazione delle modifiche',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (this.openedModal) {
                                               this.hideEnrolledUserModalChanges();
                                               this.openedModal = false;
                                           }
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);

                                       this.loading = false;

                                       if (this.openedModal) {
                                           this.hideEnrolledUserModalChanges();
                                           this.openedModal = false;
                                       }
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun Tesserato selezionato',
                             text: "Per effettuare un'operazione selezionare almeno un Tesserato",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });

                         this.loading = false;

                         if (this.openedModal) {
                             this.hideEnrolledUserModalChanges();
                             this.openedModal = false;
                         }
                     }
                 }
             })
         },

         applicaModificheInvioConiSelezionati(id) {
             let title = 'Conferma di Applicare le modifiche e inviare al CONI i Tesserati selezionati?';
             let text = "Sei sicuro di voler applicare le modifiche e inviare al CONI i Tesserati selezionati?";

             if (id !== 0) {
                 title = 'Conferma di Applicare le modifiche e inviare al CONI il Tesserato selezionato?';
                 text = "Sei sicuro di voler applicare le modifiche e inviare al CONI il Tesserato selezionato?";
             }

             Swal.fire({
                 title: title,
                 text: text,
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let ids = [];
                     if (id === 0) {
                         for (let i in this.$refs.tesseratiinmodifica.items) {
                             if (this.$refs.tesseratiinmodifica.items[i].checked) ids.push(this.$refs.tesseratiinmodifica.items[i].id);
                         }
                     }
                     else {
                         ids.push(id);
                     }

                     if (ids.length) {
                         let url = '/enrolled-user-pending-changes/apply-changes-send-register';
                         let data = { ids: ids };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Modifiche applicate e inviate al CONI correttamente', {
                                               title: 'Modifiche applicate correttamente ai Tesserati selezionati e inviate al CONI',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.tesseratiinmodifica.refresh();

                                           this.loading = false;

                                           if (this.openedModal) {
                                               this.hideEnrolledUserModalChanges();
                                               this.openedModal = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'applicazione delle modifiche o nell\'invio al CONI', {
                                               title: 'Errore nell\'applicazione delle modifiche o nell\'invio al CONI',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (this.openedModal) {
                                               this.hideEnrolledUserModalChanges();
                                               this.openedModal = false;
                                           }
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);

                                       this.loading = false;

                                       if (this.openedModal) {
                                           this.hideEnrolledUserModalChanges();
                                           this.openedModal = false;
                                       }
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun Tesserato selezionato',
                             text: "Per effettuare un'operazione selezionare almeno un Tesserato",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });

                         this.loading = false;

                         if (this.openedModal) {
                             this.hideEnrolledUserModalChanges();
                             this.openedModal = false;
                         }
                     }
                 }
             })
         },

         scartaModificheSelezionati(id) {
             let title = 'Conferma di Scartare le modifiche ai Tesserati selezionati?';
             let text = "Sei sicuro di voler scartare le modifiche ai Tesserati selezionati?";

             if (id !== 0) {
                 title = 'Conferma di Scartare le modifiche al Tesserato selezionato?';
                 text = "Sei sicuro di voler scartare le modifiche al Tesserato selezionato?";
             }

             Swal.fire({
                 title: title,
                 text: text,
                 icon: 'success',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let ids = [];
                     if (id === 0) {
                         for (let i in this.$refs.tesseratiinmodifica.items) {
                             if (this.$refs.tesseratiinmodifica.items[i].checked) ids.push(this.$refs.tesseratiinmodifica.items[i].id);
                         }
                     }
                     else {
                         ids.push(id);
                     }

                     if (ids.length) {
                         let url = '/enrolled-user-pending-changes/discard-changes';
                         let data = { ids: ids };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Modifiche scartate correttamente', {
                                               title: 'Modifiche scartate correttamente per i Tesserati selezionati',
                                               variant: 'success',
                                               solid: true
                                           });

                                           this.$refs.tesseratiinmodifica.refresh();

                                           this.loading = false;

                                           if (this.openedModal) {
                                               this.hideEnrolledUserModalChanges();
                                               this.openedModal = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'eliminazione delle modifiche', {
                                               title: 'Errore nell\'eliminazione delle modifiche',
                                               variant: 'danger',
                                               solid: true
                                           });

                                           this.loading = false;

                                           if (this.openedModal) {
                                               this.hideEnrolledUserModalChanges();
                                               this.openedModal = false;
                                           }
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);

                                       this.loading = false;

                                       if (this.openedModal) {
                                           this.hideEnrolledUserModalChanges();
                                           this.openedModal = false;
                                       }
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun Tesserato selezionato',
                             text: "Per effettuare un'operazione selezionare almeno un Tesserato",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });

                         this.loading = false;

                         if (this.openedModal) {
                             this.hideEnrolledUserModalChanges();
                             this.openedModal = false;
                         }
                     }
                 }
             })
         },

         hideEnrolledUserModalChanges() {
             let vm = this;
             setTimeout(function() {
                 vm.$refs['enrolledUserModalChanges'].hide();
             }, 200);
         },

         showEnrolledUserModalChanges(item) {
             this.loading = true;
             this.openedModal = true;

             this.currentItem = JSON.parse(JSON.stringify(item));

             let url = '/enrolled-user-pending-changes/get-changes/' + item.id;

             ApiService.query(url)
                       .then((response) => {
                           if (response.data.status === 'OK') {
                               this.fields = response.data.fields;

                               let vm = this;
                               setTimeout(function() {
                                   vm.$refs['enrolledUserModalChanges'].show();
                               }, 200);

                               this.loading = false;
                           }

                           if (response.data.status === 'KO') {
                               this.loading = false;
                           }
                       })
                       .catch(({response}) => {
                           console.log(response);

                           this.loading = false;
                       });
         },

         closeModal() {
         },
     },
 };
</script>
